<script>
import AdminLayout from "@layouts/admin";
import DataTable from "@components/data-table";
import { debounce } from "lodash";

export default {
    name: "Ledger",
    components: {
        AdminLayout,
        DataTable,
    },
    data() {
        return {
            items: [],
            count: 0,
            headers: [
                { value: "name", text: "Name", width: "165", align: "left" },
                {
                    value: "shopName",
                    text: "Business Name",
                    width: "165",
                    align: "left",
                },
                { value: "email", text: "Email", width: "210", align: "left" },
                { value: "phone", text: "Phone", width: "110", align: "left" },
                {
                    value: "amount",
                    text: "Amount",
                    width: "100",
                    align: "center",
                },
                {
                    value: "balance",
                    text: "Balance",
                    width: "100",
                    align: "center",
                },
                {
                    value: "note",
                    text: "Note",
                    width: "300",
                    align: "center",
                },
            ],
            limit: 0,
            current: 1,
        };
    },
    methods: {
        async getdata(search = null) {
            try {
                const query = {
                    limit: this.limit,
                    offset: (this.current - 1) * this.limit,
                };

                if (search) {
                    query.search = search;
                }

                const { count, rows } = await this.$http.get(
                    "dealer/find-and-count-dealers-balance",
                    query
                );

                this.items = rows;
                this.items = this.items.map((a) => ({
                    ...a,
                    shopName: a?.Dealer?.shopName,
                    balance: `₹ ${a.Dealer?.availableBalance}`,
                    amount: `₹ ${a.amount}`,
                    name: a?.Dealer?.User?.name,
                    email: a?.Dealer?.User?.email,
                    phone: a?.Dealer?.User?.phone,
                    dealerId: a.Dealer.id,
                    isApproved: a.Dealer?.isApproved
                        ? "<div class='text-green-700'>Yes</div>"
                        : "<div class='text-red-600'>No</div",
                }));
                this.count = count;
            } catch (err) {
                console.log(err);
                this.$utility.showErrorMessage(err.message);
            }
        },
        handleFetch(obj) {
            this.current = obj.current;
            this.limit = obj.limit;
            this.getdata();
        },
        debounceSearch: debounce(function (text) {
            this.getdata(text);
        }, 600),
    },
};
</script>
<template lang="pug">
admin-layout
    data-table(
        :showSearch="true",
        :count="count",
        @fetch="handleFetch",
        @search="debounceSearch"
    )
        el-table(:data="items", style="width: 100%", size="medium")
            el-table-column(
                v-for="col in headers",
                :key="col.id",
                :label="col.text",
                :width="col.width",
                :align="col.align"
            )
                template(#default="{row}")
                    span(
                        v-if="row[col.value]",
                        v-html="row[col.value]"
                    )
                    span(v-else) NA 
</template>
